import Vue from "vue";
import { firestorePlugin } from "vuefire";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";
//import { doc } from "prettier";

Vue.use(firestorePlugin);

const firebaseConfig = {
  apiKey: "AIzaSyAk1AAhQ_WOrtK69NGEPl8tnRWvGXam2kY",
  authDomain: "vocs-production.firebaseapp.com",
  databaseURL: "https://vocs-production.firebaseio.com",
  projectId: "vocs-production",
  storageBucket: "vocs-production.appspot.com",
  messagingSenderId: "4566577459",
  appId: "1:4566577459:web:cc5417ed834b9c41eb8e46",
  measurementId: "G-6EXVZKDLLS"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCRVvLPpcHknA-KHhx7ulpNwrQc-qpSatM",
//   authDomain: "vocs-development.firebaseapp.com",
//   databaseURL: "https://vocs-development.firebaseio.com",
//   projectId: "vocs-development",
//   storageBucket: "vocs-development.appspot.com",
//   messagingSenderId: "453903792332",
//   appId: "1:453903792332:web:2f4ef75f25d131b4686a69",
//   measurementId: "G-70Y4BZKNQ5",
// };

// export const FB_API_HOST = "http://localhost:5001/vocs-development/asia-northeast1";
// export const FB_API_HOST = "http://localhost:5001/vocs-production/asia-northeast1";
// export const FB_API_HOST = "https://asia-northeast1-vocs-development.cloudfunctions.net";
export const FB_API_HOST = "https://asia-northeast1-vocs-production.cloudfunctions.net";


// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();
export const fb = firebase;
export const functions = firebase.app().functions("asia-northeast1");
//fb.functions().useEmulator("localhost", 5001);

/**
 * ユーザーの各種情報を取得
 */
export const getLoginUserInfo = async () => {
  const user = firebase.auth().currentUser;
  const tokenResult = await user?.getIdTokenResult();
  const userClaims = tokenResult?.claims;

  return {
    displayName: user?.displayName ?? "",
    email: user?.email ?? "",
    apikey: userClaims?.apikey ?? "",
    uid: user?.uid ?? "",
  };
};

export const getUserID = () => {
  const user = auth.currentUser;
  return user?.uid ?? null;
};

/**
 * ユーザーの編集可能vocabulary list取得
 */
export const getUserVocabulariesList = async () => {
  const user = firebase.auth().currentUser;
  const collection = db.collection("vocabularies");
  const query = collection
    .where("app_metadata.users", "array-contains", user.uid)
    .orderBy("dcat:modified", "desc");
  const snapShot = await query.get();
  if (snapShot.empty) {
    return [];
  }
  const dataList = [];
  snapShot.forEach((oneShot) => {
    const onedoc = oneShot.data();
    dataList.push(onedoc);
  });
  return dataList;
};

/**
 * 単一のvocabulary 取得
 */

export const getTargetVocabulary = async (docId) => {
  const docRef = db.collection("vocabularies").doc(docId);
  const doc = await docRef.get();
  if (doc.exists) {
    const docData = doc.data();
    const distQS = await docRef.collection("dcat:distribution").orderBy("dcat:modified", "desc").get();
    const distList = [];
    distQS.forEach((doc) => {
      const body = doc.data();
      distList.push(body);
    });
    docData["dcat:distribution"] = distList;
    return docData;
  } else {
    throw new Error("Vocabulary not found.");
  }
};

/**
 * ローカルファイルをStorageはアップロード
 */
export const fileUploadToRevision = async (fileObj, docId) => {
  const storageRef = storage.ref();
  const newRef = storageRef.child("revisions/" + docId + "/" + fileObj.name);
  const snapShot = await newRef.putString(fileObj.data, "data_url");
  const url = await snapShot.ref.getDownloadURL();
  // return (await snapShot.ref.getDownloadURL()).split("&token")[0];
  return url;
};

export const fileUploadToRevisionOfDisribution = async (fileObj, docId, distId) => {
  const storageRef = storage.ref();
  const newRef = storageRef.child("revisions/" + docId + "/" + distId + "/" + fileObj.name);
  const snapShot = await newRef.putString(fileObj.data, "data_url");
  const url = await snapShot.ref.getDownloadURL();
  // return (await snapShot.ref.getDownloadURL()).split("&token")[0];
  return url;
};

/**
 * ローカルファイルをStorageはアップロード
 */
export const getVocabularyHistories = async (identifer) => {
  const userList = await getUserList();

  const collection = db.collection("revisions");
  const query = collection
    .where("dcat:identifier", "==", identifer)
    .orderBy("app_metadata.previousVersion", "desc");
  const snapShot = await query.get();
  if (snapShot.empty) {
    return [];
  }
  const dataList = [];
  snapShot.forEach((oneShot) => {
    const onedoc = oneShot.data();
    const app_meta = onedoc.app_metadata;
    const date = onedoc["dcat:modified"];
    const editor = userList.data.filter((user) => user.uid === app_meta.lastEditor);
    if (editor.length > 0) {
      dataList.push({
        name: editor[0].name,
        date: date,
      });
    } else {
      dataList.push({
        name: "unknown user",
        date: date,
      });
    }
  });
  return dataList;
};

/**
 * DisplayName更新
 */

export const updateUser = async (data) => {
  const func = functions.httpsCallable("updateUser");
  const res = await func(data);
  return res;
};

/**
 * Invite用ユーザーリスト取得（自分以外）
 */

export const getUserList = async () => {
  const func = functions.httpsCallable("getUserList");
  const res = await func({});
  return res;
};

/**
 * ボキャブラリーのMetaCSVをStorageから変換前オブジェクトとして取得
 */

export const loadMetaObjFromUrl = async (url) => {
  const func = functions.httpsCallable("getMetaObjFromUrl");
  const data = {
    url: url,
  };
  const res = await func(data);
  return res;
};

/*
 * FREE API
 */

/*
 * フリーで閲覧可能なvocabulary取得（status==public)
 */

export const getPublicVocabularies = async () => {
  let vocabularies = [];
  const url = FB_API_HOST + "/vocabularies/";
  const result = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
  });
  if (result.ok) {
    vocabularies = await result.json();
  }
  return vocabularies;
};

/*
 * フリーで閲覧可能なvocabulary取得（status==public) キーワード検索
 */

export const getPublicVocabulariesWithKeywords = async (keywords) => {
  let vocabularies = [];
  let url = FB_API_HOST + "/vocabularies/search";
  if (keywords.length > 0) {
    url = url + "?keywords=" + JSON.stringify(keywords);
  }
  const result = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
  });
  if (result.ok) {
    vocabularies = await result.json();
  }
  return vocabularies;
};

/*
 * Distribution本体をJSON形式にパースしてダウンロード
 */

export const getParsedDistribution = async (docID, distID) => {
  // 直接JSONLDをダウンロードするとローカルホストだとCorsに引っかかる時がある
  const url = FB_API_HOST + "/vocabularies/" + docID + "/distributions/" + distID + "/structure";
  const result = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
  });
  if (result.ok) {
    return await result.json();
  } else {
    throw new Error("Result was NG.");
  }
};

/*
 * Distribution本体をダウンロード
 */

export const getDistributionFile = async (docID, distID) => {
  // 直接JSONLDをダウンロードするとローカルホストだとCorsに引っかかる時がある
  const url = FB_API_HOST + "/vocabularies/" + docID + "/distributions/" + distID + "/download";
  const result = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
  });
  if (result.ok) {
    const text = await result.text();
    return text;
  } else {
    throw new Error("Result was NG.");
  }
};

/*
 *  Namespaces
 */

/**
 * ユーザーの編集可能 namespace 取得
 */
export const getUserNamespacesList = async () => {
  const user = firebase.auth().currentUser;
  const collection = db.collection("namespaces");
  const query = collection
    .where("users", "array-contains", user.uid)
    .orderBy("updated", "desc");
  const snapShot = await query.get();
  if (snapShot.empty) {
    return [];
  }
  const dataList = [];
  snapShot.forEach((oneShot) => {
    const onedoc = oneShot.data();
    onedoc["id"] = oneShot.id;
    dataList.push(onedoc);
  });
  return dataList;
};

/**
 * ユーザーの選択可能かつ未連携の namespace 取得
 */
export const getAvailableNamespaces = async () => {
  let namespaces = [];
  const user = firebase.auth().currentUser;
  if (!user || !user.uid) {
    // console.log('ohoho');
    return namespaces;
  }
  const url = FB_API_HOST + "/namespaces/available/" + user.uid;
  const result = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
  });
  if (result.ok) {
    namespaces = await result.json();
  }
  return namespaces;
};

/**
 * path 一致の namespace 取得
 */

export const getUserNamespaceWithPath = async (path) => {
  const pathes  = path.split('/');
  while (pathes.length > 0) {
    const nPath = pathes.join('/');
    const dL = await getNamespaceWithPath(nPath);
    if (dL.length > 0) {
      return nPath;
    } else {
      pathes.pop();
    }
  }
  return "";
};

const getNamespaceWithPath = async (path) => {
  const collection = db.collection("namespaces");
  const query = collection.where("path", "==", path);
  const snapShot = await query.get();
  if (snapShot.empty) {
    return [];
  }
  const dataList = [];
  snapShot.forEach((oneShot) => {
    const onedoc = oneShot.data();
    onedoc["id"] = oneShot.id;
    dataList.push(onedoc);
  });
  return dataList;
};

/**
 * id 一致の namespace 取得
 */

export const getNamespaceWithId = async (id) => {
  const docRef = db.collection("namespaces").doc(id);
  const doc = await docRef.get();
  if (doc.exists) {
    const docData = doc.data();
    docData.id = doc.id;
    return docData;
  } else {
    throw new Error("Namespace not found.");
  }
};

/**
 * ユーザーの各種情報を取得
 */
export const updateVocabularyStructure = async (v_id, d_id, update, keywords) => {
  try {
    const uInfo = await getLoginUserInfo();
    const header = { Authorization: 'Bearer ' + uInfo.apikey };
    let url = FB_API_HOST + "/vocabularies/" + v_id + "/structure";
    let params = [];
    if (update) {
      params.push("update=true");
    }
    if (d_id) {
      params.push("dist=" + d_id);
    }
    if (params.length > 0) {
      for (let i = 0; i < params.length; i++) {
        const oneP = params[i];
        if (i === 0) {
          url = url + "?" + oneP;
        } else {
          url = url + "&" + oneP;
        }
      }
    }
    // console.log(url)
    const result = await fetch(url, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: header,
    });
    if (result.ok) {
      const json = await result.json();
      console.log (json);
    }
    if (keywords) {
      url = FB_API_HOST + "/vocabularies/" + v_id + "/keywords?update=true";
      const r = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        headers: header,
      });
      if (r.ok) {
        const json2 = await r.json();
        console.log (json2);
      }
    }
  } catch (e) {
    console.log(e);
  }
};


/*
 *
 * Manuals
 *
 */

export const downloadDocuments = async (type) => {
  const url = FB_API_HOST + "/docs/" + type;
  const result = await fetch(url, {
    method: "GET",
    mode: "cors",
    cache: "no-cache",
  });
  if (result.ok) {
    return await result.blob()
  }
  throw new Error("dowload failed")
}