<template>
  <v-app-bar app color="white">
    <v-toolbar-title>
      <router-link :to="{ name: 'Index' }">
        <v-img
          :src="require('@/assets/logo.svg')"
          class="my-0"
          contain
          width="52"
        />
      </router-link>
      <p class="mb-0 caption">Repository of Vocabularies and Schemacolβ1.0</p>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <v-col
      cols="2"
      class="mt-8 pb-4"
    >
      <!-- <v-text-field
        append-icon="mdi-magnify"
        filled
        rounded
        dense
      ></v-text-field> -->
      <v-select
        class="pa-0 ml-auto"
        style="max-width: 100px"
        :items="languages"
        :value="sLangugae"
        @change="handleLanguage"
        mandatory
      />
    </v-col>

    <!-- <v-col v-if="isLoggedIn === true" cols="1" class="text-right">
    </v-col> -->
    <v-col  cols="1" class="text-right">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list v-if="isLoggedIn === true">
          <v-list-item>
            <v-list-item-title>
              <router-link :to="{ name: 'Create' }">{{ getWords("header_add") }}</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <router-link :to="{ name: 'Type' }">{{ getWords("header_manage") }}</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <router-link :to="{ name: 'Account' }">{{ getWords("header_account") }}</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <router-link :to="{ name: 'Document' }">{{ getWords("header_document") }}</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <a @click="logoutBtnPressed">{{ getWords("header_logout") }}</a>
            </v-list-item-title>
          </v-list-item>
        </v-list>
        <v-list v-else>
          <v-list-item>
            <v-list-item-title>
              <router-link :to="{ name: 'Document' }">{{ getWords("header_document") }}</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <router-link :to="{ name: 'Login' }">{{ getWords("header_login") }}</router-link>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-col>
    <!-- <v-col v-else cols="1" class="d-flex">
      <v-btn outlined @click="moveLogin">
        {{ getWords("header_login") }}
      </v-btn>
    </v-col> -->
  </v-app-bar>
</template>

<script>
import { auth } from "../plugins/firebase";
import words from "../assets/localewords.json";
export default {
  name: "HeaderItem",
  data: () => ({
    languages: ["English", "日本語"],
    sLangugae: "English"
  }),
  computed: {
    isLoggedIn() {
      console.log(this.$store.state.login);
      return this.$store.state.login;
    },
    getLanguage() {
      return this.$store.state.language;
    },
  },
  created() {
    const lang = this.getLanguage;
    if (lang === 'ja') {
      this.sLangugae = this.languages[1]
    } else {
      this.sLangugae = this.languages[0]
    }
  },
  methods: {
    logoutBtnPressed: function () {
      auth.signOut();
    },
    getWords (key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },
    moveLogin() {
      this.$router.push({ name: "Login" }).catch((err) => console.log(err));
    },
    handleLanguage (event) {
      if (event === "English") {
        this.$store.dispatch("commitLanguage", "en");
      } else if (event === "日本語") {
        this.$store.dispatch("commitLanguage", "ja");
      } else {
        this.$store.dispatch("commitLanguage", "en");
      }
    }
  },
};
</script>

<style scoped></style>
