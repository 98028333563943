import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      displayName: "",
      email: "",
    },
    login: false,
    vocabularies: [],
    namespaces: [],
    editParams: {
      mode: "vocabularies",
      id: "",
    },
    language: "ja",
  },
  mutations: {
    mutateUser(state, payload) {
      state.user = payload;
    },
    mutateLogin(state, status) {
      state.login = status;
    },
    mutateVocabularies(state, payload) {
      state.vocabularies = payload;
    },
    mutateNamespaces(state, payload) {
      state.namespaces = payload;
    },
    mutateEditParams(state, payload) {
      state.editParams = payload;
    },
    mutateLanguage(state, payload) {
      state.language = payload;
    },
  },
  actions: {
    commitLogin(context, payload) {
      context.commit("mutateUser", payload);
      context.commit("mutateLogin", true);
    },
    commitLogout(context) {
      context.commit("mutateUser", { displayName: "", email: "" });
      context.commit("mutateVocabularies", []);
      context.commit("mutateNamespaces", []);
      context.commit("mutateEditParams", { mode: "vocabularies", id: "" });
      context.commit("mutateLogin", false);
    },
    commitVocabularies(context, payload) {
      context.commit("mutateVocabularies", payload);
    },
    commitNamespaces(context, payload) {
      context.commit("mutateNamespaces", payload);
    },
    commitEditParams(context, payload) {
      context.commit("mutateEditParams", payload);
    },
    commitLanguage(context, payload) {
      context.commit("mutateLanguage", payload);
    },
  },
  modules: {},
});
