import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index.vue"),
  },
  // Vocabulary
  // {
  //   path: "/vocabularies/new",
  //   name: "VocabularyNew",
  //   component: () => import("../views/VocabulariesNew.vue"),
  // },
  {
    path: "/vocabularies/:id",
    name: "VocabularyShow",
    component: () => import("../views/VocabulariesView.vue"),
    // component: () => import("../views/VocabulariesDetail.vue"),
  },
  // Schema
  // {
  //   path: "/schemas/:id",
  //   name: "SchemaShow",
  //   component: () => import("../views/SchemasDetail.vue"),
  // },
  // Manage
  {
    path: "/manage",
    name: "Login",
    meta: { group: "manage" },
    component: () => import("../views/manage/Index.vue"),
  },
  {
    path: "/manage/sign-up",
    name: "SignUp",
    meta: { group: "manage" },
    component: () => import("../views/manage/SignUp.vue"),
  },
  {
    path: "/manage/account",
    name: "Account",
    meta: { group: "manage" },
    component: () => import("../views/manage/Account.vue"),
  },
  {
    path: "/manage/type",
    name: "Type",
    meta: { group: "manage" },
    component: () => import("../views/manage/Type.vue"),
  },
  // {
  //   path: "/manage/upload",
  //   name: "Upload",
  //   meta: { group: "manage" },
  //   component: () => import("../views/manage/Upload.vue"),
  // },
  {
    path: "/manage/create",
    name: "Create",
    meta: { group: "manage" },
    component: () => import("../views/manage/Create.vue"),
  },
  {
    path: "/manage/edit",
    name: "Edit",
    meta: { group: "manage" },
    component: () => import("../views/manage/Create.vue"),
  },
  {
    path: "/namespaces/create",
    name: "NameSpacesCreate",
    meta: { group: "namespaces" },
    component: () => import("../views/namespaces/Create.vue"),
  },
  {
    path: "/namespaces/edit",
    name: "NameSpacesEdit",
    meta: { group: "namespaces" },
    component: () => import("../views/namespaces/Create.vue"),
  },
  {
    path: "/document",
    name: "Document",
    meta: { group: "document" },
    component: () => import("../views/Document.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
