<template>
  <v-app>
    <header-item />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import HeaderItem from "./components/HeaderItem";
import { auth } from "./plugins/firebase";

export default {
  name: "App",

  components: {
    HeaderItem,
  },

  created() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // console.log("authState loginned")
        // console.log(user);
        if (this.$route.name !== "SignUp") {
          this.$store.dispatch("commitLogin", {displayName: user.displayName, email: user.email});
        }
      } else {
//        console.log("authState logout")
        // console.log(this.$route.name + ', ' + this.$route.path + ', ' + this.$route.path.indexOf("manage"));
        this.$store.dispatch("commitLogout");
        console.log(this.$route.name);
        if (this.$route.meta.group === "manage" && this.$route.name !== "SignUp") {
          this.$router.push({ name: "Login" }).catch((err) => console.log(err));
        }
      }
    });
  },
};
</script>
